<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-card elevation="0" class="mt-3">
    <v-data-table
      id="dataTable"
      :headers="headersRegistredUsers"
      :items="filteredLocations"
      density="compact"
      item-key="value"
      :mobile-breakpoint="600"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title></v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" data-cy="addModifyLocationDialog">
            <template #activator="{ props }">
              <v-btn
                data-cy="addLocationBtn"
                class="mb-2"
                color="primary"
                dark
                v-bind="props"
              >
                Nouveau lieu
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span data-cy="addLocationTitle" class="text-h5">{{
                  formTitle
                }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row :style="{ maxWidth: '324px' }">
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedLocation.title"
                        data-cy="addLocationName"
                        variant="outlined"
                        label="Nom"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      :style="{
                        backgroundColor: editedLocation.bgColor,
                        width: '100%',
                        height: '50%',
                        display: 'block',
                        border: '1px solid',
                        borderColor: 'black',
                        borderRadius: '10px'
                      }"
                    >
                    </v-col>
                    <v-col cols="12">
                      <v-color-picker
                        v-model="editedLocation.bgColor"
                        data-cy="addLocationColor"
                        hide-inputs
                      ></v-color-picker
                    ></v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  data-cy="addLocationCloseBtn"
                  color="blue-darken-1"
                  variant="text"
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  data-cy="addLocationSaveBtn"
                  color="blue-darken-1"
                  variant="text"
                  @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogDelete"
            data-cy="deleteLocationDialog"
            max-width="500px"
          >
            <v-card>
              <v-card-title class="text-h6"
                >Etes-vous de vouloir supprimer cet emplacement ?<br />
                <div class="text-caption">
                  Il sera retiré de tous Les événements avec celui-ci.
                </div></v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  data-cy="cancelDeleteLocationBtn"
                  color="blue-darken-1"
                  variant="text"
                  @click="closeDelete"
                  >Annuler</v-btn
                >
                <v-btn
                  data-cy="confirmDeleteLocationBtn"
                  color="blue-darken-1"
                  variant="text"
                  @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template #item.bgColor="{ value }">
        <div
          data-cy="locationColor"
          :style="{
            backgroundColor: value,
            width: '100%',
            height: '50%',
            display: 'block',
            border: '1px solid',
            borderColor: 'black',
            borderRadius: '10px'
          }"
        ></div>
      </template>
      <template #item.actions="{ item }">
        <v-tooltip bottom>
          <template #activator="{ props }">
            <v-icon
              data-cy="editLocationBtn"
              class="me-2"
              size="small"
              icon="$edit"
              v-bind="props"
              @click="editItem(item)"
            ></v-icon>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ props }">
            <v-icon
              data-cy="deleteLocationBtn"
              class="me-2"
              size="small"
              icon="$delete"
              v-bind="props"
              @click="deleteItem(item)"
            ></v-icon>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch, nextTick } from 'vue';
import {
  addLocation,
  deleteLocation,
  updateLocation
} from '/imports/api/methods/locations';
import { useStore } from 'vuex';
import log from 'loglevel';

import { IUILocation } from '/imports/ui/Interfaces/UIlocation-interface';

const store = useStore();
let headersRegistredUsers = [
  {
    title: '',
    align: 'center',
    sortable: false,
    key: 'bgColor'
  },
  {
    title: 'Lieu',
    align: 'start',
    sortable: true,
    key: 'title',
    icon: 'mdi-sort'
  },
  {
    title: 'Actions',
    align: 'center',
    sortable: false,
    key: 'actions'
  }
];

const locations = ref<IUILocation[]>([]);
let dialog = ref(false);
let dialogDelete = ref(false);
let editedIndex = ref(-1);
let showColorPicker = ref(false);
let editedLocation = ref<IUILocation>({
  title: '',
  value: '',
  bgColor: ''
});
let defaultLocation = ref<IUILocation>({
  title: '',
  value: '',
  bgColor: '#000000'
});

const filteredLocations = computed(() => {
  return locations.value.filter((location) => location.title !== '');
});
watch(dialog, (val) => {
  val || close();
});

const formTitle = computed(() =>
  editedIndex.value === -1 ? 'Nouveau lieu' : 'Editer le lieu'
);

onMounted(() => {
  locations.value = store.state.locationList;
  // Fetch locations from the store or API
});

function editItem(item: IUILocation) {
  log.info('editItem', item);
  editedIndex.value = locations.value.indexOf(item);
  log.info('editItem', editedIndex.value);
  editedLocation.value = Object.assign({}, item);
  dialog.value = true;
}

function deleteItem(item: IUILocation) {
  editedIndex.value = locations.value.indexOf(item);
  editedLocation.value = Object.assign({}, item);
  dialogDelete.value = true;
}

function deleteItemConfirm() {
  deleteLocation.call({ value: editedLocation.value.value });
  store.commit('deleteLocation', editedIndex.value);
  closeDelete();
}

function close() {
  dialog.value = false;
  nextTick(() => {
    editedLocation.value = Object.assign({}, defaultLocation.value);
    editedIndex.value = -1;
    showColorPicker.value = false;
  });
}

function closeDelete() {
  dialogDelete.value = false;
  nextTick(() => {
    editedLocation.value = Object.assign({}, defaultLocation.value);
    editedIndex.value = -1;
  });
}

/**
 * Saves the edited location. If the location is being edited (editedIndex > -1),
 * it updates the location if the title has not changed or if there is no existing location
 * with the same title. If the location is new (editedIndex === -1), it adds the location
 * if there is no existing location with the same title.
 *
 * @async
 * @function save
 * @returns {Promise<void>} - A promise that resolves when the save operation is complete.
 */
async function save() {
  const titleLowerCase = editedLocation.value.title.toLowerCase();
  const existingLocation = locations.value.find(
    (location: IUILocation) => location.value === titleLowerCase
  ) as IUILocation | undefined;

  if (editedIndex.value > -1) {
    if (titleLowerCase === editedLocation.value.value || !existingLocation) {
      await updateLocation.callAsync(editedLocation.value);
      store.commit('updateLocation', editedLocation.value);
      return close();
    }
  } else {
    if (!existingLocation) {
      editedLocation.value.value = titleLowerCase;
      await addLocation.callAsync(editedLocation.value);
      store.commit('addLocation', editedLocation.value);
    }
  }
  close();
}
</script>

<style scoped>
.v-card {
  max-width: 800px;
  margin: auto;
}
.v-icon {
  cursor: pointer;
  transition: color 0.3s;
}

.v-icon:hover {
  color: #1976d2;
}
</style>
